div.logo {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
}

div.logo > svg.logo {
  width: 50px;
  padding: 15px 15px 15px 0;
}

div.logo-text {
  font-size: 40px;
  font-weight: 400;
  color: #000654;
  align-items: center;
}

b.logo-bold {
  font-weight: 500;
  color: #FF3860;
}

.logoa,
.logob {
  fill: none;
  stroke: #000654;
  stroke-miterlimit: 10;
  stroke-width: 2.5px;
}

.logob {
  stroke-linecap: square;
}

.logoc {
  fill: #ff3860;
}