.partner h1 {
  font-weight: 300;
  font-size: 60px;
  /* padding: 40px 30vw 40px 40px; */
  margin: 0px;
  color: #000d29;
  margin-bottom: 32px;
}

.partner h3 {
  font-weight: 400;
  font-size: 24px;
  margin: 0px;
  color: #000d29;
  font-family: 'Gotham A', 'Gotham B';
  font-style: normal;
}

.flare {
  float: right;
  max-width:287px;
  height:auto;
  width:auto;
  margin-top: 55px;
}