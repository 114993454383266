.other .item {
  margin-bottom: 11px;
}

.other .big-item {
  margin-top: 144px;
}


.other h1  {
  width: 100%;
  margin-bottom: 21px;
}

.other p {
  font-size: 24px;
  font-weight: 400;
  line-height: 1.4;
}

.other img {
  width:30vw;
  max-width:400px;
  padding: 20px;
}