
html, body, #root {
  height: 100%;
}

footer {
  background-color: #f5f8ff;
}

footer a {
  font-family: 'Gotham A', 'Gotham B';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #000;
  text-decoration: none;
}

footer .sponsors {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

footer .sponsors > img {
  width: 120px;
}

.trademark {
  font-size: 10px;
}

@media only screen and (max-width: 700px) {
  footer div.sponsors > img {
    padding: 3px;
    margin: 0px;
    width: 65px;
  }
}
